import {CSSStyles} from '../../../types/CSSStyles';

import {css} from '@emotion/react';
import React from 'react';
import {Link as LinkImpl, LinkProps} from 'react-router-dom';

export interface Props extends LinkProps {
  sx?: CSSStyles;
}

export default function Link(props: Props): JSX.Element {
  const {children, sx, to} = props;

  return (
    <LinkImpl
      css={[
        css({
          boxSizing: 'border-box',
        }),
        sx,
      ]}
      to={to}
    >
      {children}
    </LinkImpl>
  );
}

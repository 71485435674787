import {ButtonSize} from './options';
import useAppTheme from '../hooks/useAppTheme';

import {SerializedStyles, css} from '@emotion/react';
import {CSSInterpolation} from '@emotion/serialize';

export interface LabelStyleOptions {
  readonly size?: ButtonSize;
}

export default function useLabelStyles(
  options: LabelStyleOptions,
): SerializedStyles {
  const {fontFamily} = useAppTheme();
  const {size} = options;

  let labelStyles: CSSInterpolation;
  switch (size) {
    case ButtonSize.MEDIUM:
      labelStyles = {
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '13px',
      };
      break;
    case ButtonSize.LARGE:
    default:
      labelStyles = {
        fontSize: 13,
        fontWeight: 700,
        lineHeight: '13px',
      };
  }

  return css({
    fontFamily,
    whiteSpace: 'nowrap',
    ...labelStyles,
  });
}

import {ButtonColor, ButtonSize} from './options';
import useAppTheme from '../hooks/useAppTheme';
import brown from '../theme/colors/brown';
import white from '../theme/colors/white';
import {createPurePalette} from '../theme/palettes';

import {SerializedStyles, css} from '@emotion/react';
import {CSSInterpolation} from '@emotion/serialize';

export interface ButtonStyleOptions {
  readonly color?: ButtonColor;
  readonly disabled?: boolean;
  readonly size?: ButtonSize;
}

const TRANSPARENT_PALETTE = createPurePalette('transparent');

export default function useButtonStyle(
  options: ButtonStyleOptions,
): SerializedStyles {
  const {color = ButtonColor.PRIMARY, disabled, size} = options;
  const theme = useAppTheme();
  const backgroundPalette = theme.palettes.background;
  const contrastPalette = backgroundPalette.contrast;
  let backgroundColor;
  let contrastColor;
  let borderColor;
  switch (color) {
    case ButtonColor.ACCENT:
      backgroundColor = backgroundPalette.accent;
      contrastColor = contrastPalette.accent;
      borderColor = backgroundPalette.accent;
      break;
    case ButtonColor.SECONDARY:
      backgroundColor = {
        ...backgroundPalette.neutralExtraSubdued,
        default: white.solid,
      };
      contrastColor = contrastPalette.neutralExtraSubdued;
      borderColor = backgroundPalette.neutral;
      break;
    case ButtonColor.SECONDARY_SUBDUE:
      backgroundColor = {
        ...backgroundPalette.neutralExtraSubdued,
        default: white.solid,
        active: white.solid,
      };
      contrastColor = contrastPalette.neutralExtraSubdued;
      borderColor = {
        ...backgroundPalette.neutral,
        default: brown.brown300,
        hover: brown.brown300,
      };
      break;
    case ButtonColor.SECONDARY_CONTRAST:
      backgroundColor = TRANSPARENT_PALETTE;
      contrastColor = backgroundPalette.neutralExtraSubdued;
      borderColor = backgroundPalette.neutralExtraSubdued;
      break;
    case ButtonColor.TRANSPARENT:
      backgroundColor = TRANSPARENT_PALETTE;
      contrastColor = backgroundPalette.neutralExtraSubdued;
      borderColor = TRANSPARENT_PALETTE;
      break;
    case ButtonColor.PRIMARY_CONTRAST:
      backgroundColor = backgroundPalette.neutralContrast;
      contrastColor = contrastPalette.neutralSubdued;
      borderColor = TRANSPARENT_PALETTE;
      break;
    case ButtonColor.PRIMARY:
    default:
      backgroundColor = backgroundPalette.neutral;
      contrastColor = contrastPalette.neutral;
      borderColor = backgroundPalette.neutral;
  }

  let borderRadius;
  let padding;
  switch (size) {
    case ButtonSize.SMALL:
      borderRadius = '10px';
      padding = 6;
      break;
    case ButtonSize.LARGE:
      borderRadius = '20px';
      padding = '7px 24px 10px 24px';
      break;
    case ButtonSize.MEDIUM:
    default:
      borderRadius = '16px';
      padding = '6px 19px 6px 19px';
  }

  const sizeStyles: CSSInterpolation = {
    borderRadius,
    padding,
  };

  const cssOptions: CSSInterpolation = {
    backgroundColor: backgroundColor.default,
    borderColor: borderColor.default,
    borderStyle: 'solid',
    borderWidth: 2,
    boxSizing: 'border-box',
    color: contrastColor.default,
    cursor: 'pointer',
    ...sizeStyles,

    '&:focus-visible': {
      backgroundColor: backgroundColor.focus,
      borderColor: borderColor.focus,
      color: contrastColor.focus,
      outline: `1.5px solid ${theme.palettes.background.accent.focus}`,
      outlineOffset: 2,
    },

    '&:hover': {
      backgroundColor: backgroundColor.hover,
      borderColor: borderColor.hover,
      color: contrastColor.hover,
    },

    '&:active': {
      backgroundColor: backgroundColor.active,
      borderColor: borderColor.active,
      color: contrastColor.active,
    },
  };

  if (disabled) {
    cssOptions.pointerEvents = 'none';
    cssOptions.backgroundColor = backgroundColor.disabled;
    cssOptions.borderColor = borderColor.disabled;
    cssOptions.color = contrastColor.disabled;
  }

  return css(cssOptions);
}
